<template>
	<div class="">
		<b-row class="mb-2">
			<!-- Date picker -->
			<b-col cols="12" md="6" class="px-3">
				<b-row>
					<b-col md="5 p-0 mr-1">
						<label for="example-input">From Date</label>
						<b-form-datepicker
							id="from-date"
							:date-format-options="{
								year: 'numeric',
								month: 'short',
								day: '2-digit',
								weekday: 'short',
							}"
							placeholder="Choose a date"
							local="en"
							v-model="search_filter.from_date"
						/>
					</b-col>
					<b-col md="5 p-0 ml-1">
						<label for="example-input">To Date</label>
						<b-form-datepicker
							id="to-date"
							:date-format-options="{
								year: 'numeric',
								month: 'short',
								day: '2-digit',
								weekday: 'short',
							}"
							placeholder="Choose a date"
							local="en"
							v-model="search_filter.to_date"
						/>
					</b-col>
					<b-col md="1 d-flex align-items-end">
						<b-button @click="getFeedPurchaseList()" variant="primary "> Filter </b-button>
					</b-col>
				</b-row>
			</b-col>
			<!-- Search -->
			<b-col cols="12" md="6" class="pl-5 mt-2 d-flex justify-content-end align-items-center">
				<div>
					<b-form-input
						type="text"
						class="form-control"
						placeholder="Search"
						@input="getFeedPurchaseList()"
						v-model.trim="search_filter.input_field"
						style="border-radius: 4px"
					/>
				</div>
				<b-button
					@click="clearFilter"
					variant="secondary
 			 ml-1"
				>
					Clear
				</b-button>
				<b-button
					:to="{
						name: 'apps-purchase-feed-add',
					}"
					variant="primary mr-1 ml-2"
				>
					Add
				</b-button>
				<b-button @click="excelDownload()" variant="warning">
					<feather-icon icon="DownloadIcon" size="16" />
				</b-button>
			</b-col>
		</b-row>
		<div v-if="feedPurchaseList && feedPurchaseList.total > 0">
			<b-table
				id="feedPurchaseList"
				:items="feedPurchaseList.data"
				responsive
				:fields="fields"
				class="position-relative"
			>
				<template #cell(index)="data">
					<div class="ml-1">
						{{ feedPurchaseList.from + data.index }}
					</div>
				</template>
				<template #cell(feed)="data">
					<span v-if="data.item.feed">{{ data.item.feed.name }}</span>
					<span v-else>NA</span>
				</template>
				<template #cell(production_type)="data">
					<span v-if="data.item.production_type">{{ data.item.production_type.name }}</span>
					<span v-else>NA</span>
				</template>
				<!-- <template #cell(breed)="data">
					<span v-if="data.item.breed">{{ data.item.breed.name }}</span>
					<span v-else>NA</span>
				</template>
				<template #cell(breed_stage)="data">
					<span v-if="data.item.breed_stage">{{ data.item.breed_stage.name }}</span>
					<span v-else>NA</span>
				</template> -->
				<template #cell(feed_type)="data">
					<span v-if="data.item.feed_type">{{ data.item.feed_type.name }}</span>
					<span v-else>NA</span>
				</template>
				<template #cell(source)="data">
					<span v-if="data.item.source">{{ data.item.source.name }}</span>
					<span v-else>NA</span>
				</template>
			</b-table>
			<div class="mt-2 mb-5">
				<b-pagination
					:value="feedPurchaseList.current_page"
					:total-rows="feedPurchaseList.total"
					:per-page="feedPurchaseList.per_page"
					@change="getFeedPurchaseList"
					align="right"
				>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">Feed Purchase List Empty</h4>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			searchQuery: null,
			page: null,
			fields: [
				{ key: "index", label: "S.no" },
				,
				{
					key: "purchase_date",
					label: "purchase date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},

				"production_type",
				// "breed",
				// "breed_stage",
				{
					key: "feed",
					label: "feed name",
				},
				"feed_type",
				"source",
				// "batch_no",
				"quantity",
				"rate_per_kg",
				"total_cost",
			],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			feedPurchaseList: {},
		};
	},
	created: function () {
		this.getFeedPurchaseList();
	},
	methods: {
		moment() {
			return moment();
		},

		getFeedPurchaseList(pageNo = 1) {
			const farm_id = this.$route.params.farmId;
			let url = `web/farm/${farm_id}/feed-purchase`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.feedPurchaseList = response.data.feedPurchase;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-feed-purchase`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getFeedPurchaseList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getFeedPurchaseList();
				});
		},

		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getFeedPurchaseList();
		},
	},
};
</script>

<style lang="scss" scoped>
.activeClass {
	background-color: #296db4;
	color: #fff;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
